import clsx from 'clsx';
import { NextPage } from 'next';
import React from 'react';

import s from 'src/styles/404.module.scss';
import { useRouter } from 'next/router';
import { Button } from '@chakra-ui/react';

import NotFoundLine from 'src/assets/images/404.svg';
import NotFoundLine2 from 'src/assets/images/404_1.svg';

const NotFoundPage: NextPage = () => {
  const router = useRouter();

  return (
    <div className={s.root}>
      <NotFoundLine className={s.line} />
      <NotFoundLine2 className={s.line} />
      <div className={s.wrapper}>
        <div className={s.title}>404</div>
        <Button
          variant="transparent"
          className={s.btn}
          onClick={() => router.push('/')}
        >
          Go back to Main page
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
